import React from 'react'

interface CommerceBearIconProps extends React.SVGAttributes<SVGElement> {
  children?: never
}

export const CommerceBearIcon = (props: CommerceBearIconProps) => {
  return (
    <svg width="15" height="16" viewBox="0 0 252 272" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_1518_67)">
        <path
          d="M249.499 95.5906C247.009 90.4877 242.361 86.847 233.063 79.5612L146.431 11.6332C136.614 3.94516 131.714 0.097641 125.993 0.097641C120.272 0.097641 115.372 3.94516 105.558 11.6332L18.9233 79.5588C9.625 86.8447 4.98167 90.4853 2.48733 95.5882C0 100.693 0 106.587 0 118.373V239.072C0 254.587 0 262.357 4.844 267.171C9.688 271.998 17.4743 271.998 33.047 271.998H108.164L114.49 210.135H79.3077C72.5177 210.135 69.1227 210.135 66.6493 208.573C65.3963 207.78 64.3347 206.722 63.539 205.474C61.9687 203.01 61.9687 199.627 61.9687 192.862V142.865C61.9687 136.1 61.9687 132.717 63.539 130.253C64.3347 129.005 65.3963 127.947 66.6493 127.154C69.1227 125.59 72.5177 125.59 79.3077 125.59H172.692C179.482 125.59 182.877 125.59 185.351 127.154C186.604 127.947 187.665 129.005 188.461 130.253C190.031 132.717 190.031 136.1 190.031 142.865V192.862C190.031 199.627 190.031 203.012 188.461 205.474C187.665 206.722 186.604 207.78 185.351 208.573C182.877 210.135 179.482 210.135 172.692 210.135H137.51L143.836 271.998H218.953C234.537 271.998 242.324 271.998 247.156 267.171C252 262.345 252 254.587 252 239.072V118.373C252 106.587 252 100.71 249.51 95.5882H249.499V95.5906Z"
          fill="#DBAB00"
        />
      </g>
      <defs>
        <clipPath id="clip0_1518_67">
          <rect width="252" height="272" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
